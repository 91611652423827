import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/home/index.vue'

import store from '@/store/index'
import $aiJson from '@/assets/json/index.js';
import $utils from '@/utils/index.js';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
    },
    {
        path: '/organization/index',
        name: 'organization',
        component: () => import('../views/organization/index.vue')
    },
    {
        path: '/team/index',
        name: 'team',
        component: () => import('../views/team/index.vue')
    },
    {
        path: '/smartPitch/index',
        name: 'smartPitch',
        component: () => import('../views/smartPitch/index.vue')
    },
    {
        path: '/smartPitch/camera',
        name: 'smartPitchDetail',
        component: () => import('../views/smartPitch/details.vue')
    },
    {
        path: '/introduce/index',
        name: 'introduce',
        component: () => import('../views/introduce/index.vue')
    },
    {
        path: '/introduce/moment',
        name: 'moment',
        component: () => import('../views/introduce/moment.vue')
    },
    {
        path: '/introduce/video',
        name: 'videoIntroduce',
        component: () => import('../views/introduce/video.vue')
    },
    {
        path: '/menu/index',
        name: 'mainMenu',
        component: () => import('../views/menu/index.vue')
    },
    {
        path: '/teamSummary/index',
        name: 'teamSummary',
        meta: { title: '球队总结' },
        component: () => import('../views/teamSummary/index.vue')
    },
    {
        path: '/trainingPlan/index',
        name: 'trainingPlan',
        meta: { title: '训练计划' },
        component: () => import('../views/trainingPlan/index.vue')
    },
    {
        path: '/trainingPlan/details',
        name: 'trainingPlanDetails',
        meta: { title: '训练计划' },
        component: () => import('../views/trainingPlan/details.vue')
    },
    {
        path: '/injuryReport/index',
        name: 'injuryReport',
        meta: { title: '伤病报告' },
        component: () => import('../views/injuryReport/index.vue')
    },
    {
        path: '/injuryReport/currentDetails',
        name: 'injuryReportCurrentDetails',
        meta: { title: '伤病报告详情' },
        component: () => import('../views/injuryReport/currentDetails.vue')
    },
    {
        path: '/injuryReport/details',
        name: 'injuryReportDetails',
        meta: { title: '伤病报告详情' },
        component: () => import('../views/injuryReport/details.vue')
    },
    {
        path: '/videoList/index',
        name: 'videoList',
        meta: { title: '视频' },
        component: () => import('../views/videoList/index.vue')
    },
    {
        path: '/videoList/clipsList',
        name: 'videoClipsList',
        meta: { title: '视频短片列表' },
        component: () => import('../views/videoList/clipsList.vue')
    },
    {
        path: '/videoList/details',
        name: 'videoDetails',
        meta: { title: '视频详情' },
        component: () => import('../views/videoList/details.vue')
    },
    {
        path: '/playerList/index',
        name: 'playerList',
        meta: { title: '球员信息' },
        component: () => import('../views/playerList/index.vue')
    },
    {
        path: '/playerList/details',
        name: 'playerDetails',
        meta: { title: '球员信息详情' },
        component: () => import('../views/playerList/details.vue')
    },
    {
        path: '/playerList/evalDetail',
        name: 'playerEvalDetail',
        meta: { title: '球员评估' },
        component: () => import('../views/playerList/evalDetail.vue')
    },
    {
        path: '/playerList/evalDetail-data',
        name: 'playerEvalDetailData',
        meta: { title: '球员评估详情' },
        component: () => import('../views/playerList/evalDetail-data.vue')
    },

    {
        path: '/staffList/index',
        name: 'staffList',
        meta: { title: '教练员信息' },
        component: () => import('../views/staffList/index.vue')
    },
    {
        path: '/staffList/details',
        name: 'staffDetails',
        meta: { title: '教练员信息详情' },
        component: () => import('../views/staffList/details.vue')
    },
    {
        path: '/staffList/evalDetail',
        name: 'staffEvalDetail',
        meta: { title: '教练员评估' },
        component: () => import('../views/staffList/evalDetail.vue')
    },
    {
        path: '/exerciseList/index',
        name: 'exerciseList',
        meta: { title: '训练教案' },
        component: () => import('../views/exerciseList/index.vue')
    },
    {
        path: '/exerciseList/details',
        name: 'exerciseDetails',
        meta: { title: '训练教案详情' },
        component: () => import('../views/exerciseList/details.vue')
    },
    {
        path: '/elitePlayers/index',
        name: 'elitePlayers',
        meta: { title: '精英球员' },
        component: () => import('../views/elitePlayers/index.vue')
    },
    {
        path: '/topAnalysis/index',
        name: 'topAnalysis',
        meta: { title: 'TOP榜单 - 分析' },
        component: () => import('../views/topAnalysis/index.vue')
    },
    {
        path: '/topPhysical/index',
        name: 'topPhysical',
        meta: { title: 'TOP榜单 - 体测' },
        component: () => import('../views/topPhysical/index.vue')
    },
    {
        path: '/teamMatch/index',
        name: 'teamMatch',
        meta: { title: '梯队赛程' },
        component: () => import('../views/teamMatch/index.vue')
    },
    {
        path: '/teamMatch/details',
        name: 'teamMatchDetails',
        meta: { title: '梯队赛程详情' },
        component: () => import('../views/teamMatch/details.vue')
    },
    {
        path: '/matchReport/index',
        name: 'matchReport',
        meta: { title: '智能比赛报告' },
        component: () => import('../views/matchReport/index.vue')
    },
    {
        path: '/matchReport/detail',
        name: 'matchReportDetail',
        meta: { title: '智能比赛报告' },
        component: () => import('../views/matchReport/detail.vue')
    },


    // 数据报告 start -------------------------------------------------
    {
        path: '/dataReport/index',
        name: 'dataReport',
        meta: { title: '数据报告' },
        component: () => import('../views/dataReport/index.vue')
    },
    {
        path: '/dataReport/matchDay/index',
        name: 'matchDay',
        meta: { title: '比赛日分析' },
        component: () => import('../views/dataReport/matchDay/index.vue')
    },
    {
        path: '/dataReport/matchDay/details',
        name: 'matchDayDetails',
        meta: { title: '比赛日分析详情' },
        component: () => import('../views/dataReport/matchDay/details.vue')
    },
    {
        path: '/dataReport/playerAnalysis/index',
        name: 'playerAnalysis',
        meta: { title: '球员数据分析' },
        component: () => import('../views/dataReport/playerAnalysis/index.vue')
    },
    {
        path: '/dataReport/playerAnalysis/details',
        name: 'playerAnalysisDetails',
        meta: { title: '球员数据分析详情' },
        component: () => import('../views/dataReport/playerAnalysis/details.vue')
    },
    {
        path: '/dataReport/intensityReport/index',
        name: 'intensityReport',
        meta: { title: '强度报告' },
        component: () => import('../views/dataReport/intensityReport/index.vue')
    },
    {
        path: '/dataReport/intensityReport/details',
        name: 'intensityReportDetails',
        meta: { title: '强度报告详情' },
        component: () => import('../views/dataReport/intensityReport/details.vue')
    },
    {
        path: '/dataReport/teamAnalysis/index',
        name: 'teamAnalysis',
        meta: { title: '梯队数据报告' },
        component: () => import('../views/dataReport/teamAnalysis/index.vue')
    },
    {
        path: '/dataReport/acReport/index',
        name: 'acReport',
        meta: { title: 'AC报告' },
        component: () => import('../views/dataReport/acReport/index.vue')
    },
    {
        path: '/dataReport/acReport/details',
        name: 'acReportDetails',
        meta: { title: 'AC报告详情' },
        component: () => import('../views/dataReport/acReport/details.vue')
    },
    {
        path: '/dataReport/aimReport/index',
        name: 'aimReport',
        meta: { title: 'AC目标报告' },
        component: () => import('../views/dataReport/aimReport/index.vue')
    },
    {
        path: '/dataReport/aimReport/details',
        name: 'aimReportDetails',
        meta: { title: 'AC目标报告详情' },
        component: () => import('../views/dataReport/aimReport/details.vue')
    },
    // 数据报告 end-----------------------------------------------------------------

    {
        path: '/talent/index',
        name: 'talent',
        meta: { title: '精英人才库' },
        component: () => import('../views/talent/index.vue')
    },
    {
        path: '/talent/details',
        name: 'talentDetails',
        meta: { title: '精英人材库详情' },
        component: () => import('../views/talent/details.vue')
    },

    {
        path: '/dreamTeam/index',
        name: 'dreamTeam',
        meta: { title: '最佳阵容' },
        component: () => import('../views/dreamTeam/index.vue')
    },

    {
        path: '/school/index',
        name: 'school',
        meta: { title: '校区' },
        component: () => import('../views/school/index.vue')
    },
    {
        path: '/school/details',
        name: 'schoolDetails',
        meta: { title: '校区详情' },
        component: () => import('../views/school/details.vue')
    },

    {
        path: '/camp/index',
        name: 'camp',
        meta: { title: '训练营列表' },
        component: () => import('../views/camp/index.vue')
    },
    {
        path: '/camp/summary',
        name: 'campSummary',
        meta: { title: '训练营总结报告' },
        component: () => import('../views/camp/campSummary.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 全屏设置 fullScreen内是 路由name白名单
router.beforeEach((to, from, next) => {
    let fullScreen = [
        'home',
        'login',
        'playerDetails',
        'playerEvalDetail',
        'playerEvalDetailData',
        'staffDetails',
        'staffEvalDetail',
        'injuryReportCurrentDetails',
        'injuryReportDetails',
        'videoList',
        'videoClipsList',
        'smartPitchDetail',
        'talentDetails',
        'campSummary',
        'teamMatchDetails'
    ];
    store.commit('setAnimationType', 'defaultAi');
    if (fullScreen.indexOf(to.name) > -1) {
        store.commit('setFullScreen', true)
    }else {
        store.commit('setFullScreen', false)
    }

    // 到登录页，获取机构页面时清空之前选的机构id ：x-tenant-id。清空后会默认传 parentId：集团id
    let clearTenantData = [
        'home',
        'login',
        'organization'
    ]
    if (clearTenantData.indexOf(to.name) > -1) {
        store.commit('setTenantData', '')
    }

    next()
})

// 跳转后播放对应话术
router.afterEach((to, from, failure) => {
    const routeData = $aiJson.routeList[to.name] || $aiJson.dataReport[to.name] || $aiJson.talentReport[to.name] || $aiJson.otherList[to.name];
    console.log(to.name)
    console.log(from.name)
    console.log(routeData)


    if (routeData && routeData.key !== "login") {
        if (routeData.playVoiceArr && routeData.playVoiceArr.length) {
            $utils.playVoiceFn(routeData.playVoiceArr, routeData.animationType)
        }else {
            if (routeData.isConcat.flag) {
                let concatText = '';
                if (routeData.isConcat.concatType === 'player') {
                    // to.query.name 路由参数 需要播放(人名＋话术)时用
                    concatText = to.query.name;
                }else if (routeData.isConcat.concatType === 'tenant') {
                    concatText = store.state.tenantData.name ? store.state.tenantData.name : '机构名称'
                }else {
                    concatText = store.state.teamData.name ? store.state.teamData.name : '梯队名称'
                }

                $utils.playVoiceFn(['这是' + concatText + routeData.playVoice], routeData.animationType)
            } else {
                $utils.playVoiceFn([routeData.playVoice], routeData.animationType)
            }
        }
    }

})
export default router
