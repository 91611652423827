import axios from './axios';
import store from "../store";
import config from "../config";

const server = {
    // key=教案标签：tags；教案年龄：age；比赛阶段：stage；训练赛节数：section；
    // 比赛阵型：formation；比赛事件：matchevent；工作项类型：itemtype；
    // 职务：title；名单类别：listType；球员位置：position；综合评分：total
    // 球员来源：sourceType  女足年龄段：wheel 教练员职称列表：visual_title
    // 青训营视频标签：videoLable
    // 评估中英文：
    getCommonData(params) {
        //查询通用定义数据
        return axios('/common/data', {
            method: 'get',
            params,
        })
    },
    // 获取职位
    getJobList(params) {
        return axios('/job/list', {
            method: 'get',
            params,
        })
    },
    pushImgOss(data) {
        //上传人脸识别图片
        return axios('/search_face', {
            method: 'post',
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            handleTipsBySelf: true, // 自己处理返回值
        });
    },
    getToken(data) {
        //上传人脸识别图片
        return axios(`${config.cmsUrl}/guard/face-to-pass`, {
            method: 'post',
            data
        });
    },
    // 获取集团下的子机构
    getTenantList(params) {
        return axios(`${config.cmsUrl}/guard/tenant`, {
            method: 'get',
            params
        })
    },
    // 获取所有工作项
    getAllWorkItem(params) {
        return axios('/statistics/week/workitem', {
            method: 'get',
            params
        })
    },
    // gps报告模块 --  gps数据 -- 筛选项请求（跑动距离等）  /catapult/params
    getGpsFilter(params) {
        //梯队球员数据
        return axios('/params', {
            method: 'get',
            params,
        });
    },

    // 比赛日数据分析报告
    getMatch_dateReport(params) {
        return axios('/gps/match_date/report', {
            method: 'get',
            params,
        });
    },
    // 获取gps球员数据分析
    getGpsPlayerReport(params) {
        return axios('/gps_player_report', {
            method: 'get',
            params,
        });
    },
    // 获取gps梯队数据分析
    getGpsReport(params) {
        return axios('/gps_report', {
            method: 'get',
            params,
        });
    },
    //AC报告
    getACReport(params) {
        return axios('/ac_report', {
            method: 'get',
            params
        })
    },
    //AIM报告
    getAIMReport(params) {
        return axios('/aim_report', {
            method: 'get',
            params
        })
    },
    //获取所有球队列表
    getTeamList(params) {
        return axios('/teams', {
            method: 'get',
            params
        })
    },

    // 精英人才
    getEliteList(params) {
        return axios('/elite/list', {
            method: 'get',
            params
        })
    },
    // 所以球员
    getAllPlayersList(params) {
        return axios('/player/list', {
            method: 'get',
            params
        })
    },
    // 球员列表
    getPlayersList(data) {
        return axios(config.cmsUrl + '/clubhub/team/users', {
            method: 'post',
            data: {
                ...data,
                type: 'player'
            }
        })
    },

    // 登录人详情
    getUser(data) {
        return axios(config.cmsUrl + '/clubhub/user/base', {
            method: 'POST',
            data
        })
    },

    // 球员列详情
    getUserMsg(data) {
        return axios(config.cmsUrl + '/clubhub/user/info', {
            method: 'POST',
            data
        })
    },
    // 用户梯队列表
    getUserTeams(params) {
        return axios('/user/teams', {
            method: 'get',
            params
        })
    },

    // 获取球队内所有职员列表
    // 获取球队内所有职员列表
    getTeamStaff(data) {
        return axios(config.cmsUrl + '/clubhub/team/users', {
            method: 'post',
            data: {
                ...data,
                type: 'staff'
            }
        })
    },

    // 伤病
    // 获取伤病报告
    injuryList(data) {
        return axios(config.cmsUrl + '/injury/card/list/current', {
            method: 'post',
            data
        })
    },

    // 获取训练营伤病报告
    campInjuryList(params) {
        return axios('/teens_camp/injury_report', {
            method: 'get',
            params
        })
    },
    // 伤病历史
    injuryHistory(data) {
        return axios(config.cmsUrl + '/injury/treatment/list', {
            method: 'post',
            data
        })
    },

    // 当前伤病信息
    injuryDetail(data) {
        return axios(config.cmsUrl + '/injury/card/detail', {
            method: 'post',
            data
        })
    },

    //视频list
    getVideoList(params) {
        return axios('/videos', {
            method: 'get',
            params,
        });
    },
    //视频list
    getVideoClipsList(params) {
        return axios('/video/clips', {
            method: 'get',
            params,
        });
    },
    //视频详情
    getVideo(params) {
        return axios('/video', {
            method: 'GET',
            params,
        });
    },
    // 球员短片列表
    getPlayerVideo(params) {
        return axios('/player/clips', {
            method: 'GET',
            params,
        });
    },
    // 练习教案list
    getExerciseList(params) {
        return axios(config.cmsUrl +'/library/exercise/list', { //教案
            method: 'get',
            params,
        });
    },
    // 练习教案详情
    getExerciseDetail(params) {
        return axios(config.cmsUrl +'/library/exercise', {
            method: 'get',
            params,
        });
    },
    // 当周和当天训练日程
    weekSchedule(params) {
        return axios('/schedule/week', {
            method: 'get',
            params
        })
    },
    // 计算当前周编号
    nowWeek(params) {
        return axios('/current_week_index', {
            method: 'get',
            params
        })
    },
    // 球队总结
    teamReport(params) {
        return axios('/team/overview', {
            method: 'get',
            params
        })
    },
    // 获取所有周数据--柱状图显示
    getPlayersReportWeekAll(params) {
        return axios('/evaluation/list', {
            method: 'get',
            params
        })
    },

    // 梯队赛程 -- 统计
    getTeamMatchOverview(params) {
        return axios('/match/overview', {
            method: 'get',
            params
        })
    },
    // 梯队赛程 -- 列表
    getTeamMatchSchedule(params) {
        return axios('/match/schedule', {
            method: 'get',
            params
        })
    },

    // 比赛报告 -- 列表
    getMatchReportSchedule(params, headers) {
        return axios('/match_report/list', {
            method: 'get',
            params,
            headers,
        })
    },
    // 校区
    getSchoolList(params) {
        return axios('/base/campus', {
            method: 'get',
            params
        })
    },
    // 训练营列表
    getCampList(params) {
        return axios('/teens_camp/list', {
            method: 'get',
            params
        })
    },
    // 训练营 - 训练计划
    getCampWorkItem(params) {
        return axios('/teens_camp/schedules', {
            method: 'get',
            params
        })
    },
    // 训练营 - 视频
    getCampVideos(params) {
        return axios('/teens_camp/videos', {
            method: 'get',
            params
        })
    },
    // 训练营 - 球员
    getCampPlayer(params) {
        return axios('/teens_camp/players', {
            method: 'get',
            params
        })
    },
    // 训练营 - 教练员
    getCampStaff(params) {
        return axios('/teens_camp/staffs', {
            method: 'get',
            params
        })
    },
    // 训练营-精英球员
    getElites(params) {
        return axios('/teens_camp/elites', {
            method: 'get',
            params
        })
    },
    // 最佳阵容
    getHonorList(params) {
        return axios('/honor/list', {
            method: 'get',
            params
        })
    },
    // 获取青训营视频标签
    getVideoClipLabel(params) {
        return axios('/teens_camp/videoClipLabel', {
            method: 'get',
            params
        })
    },
    // TOP榜单 - 分析
    teensCampClipListAnalysis(params) {
        return axios('/teens_camp/top', {
            method: 'get',
            params,
        });
    },
    // TOP榜单 - 体测 / 获取筛选项
    getPhysicalTemplates(params) {
        return axios('/teens_camp/physical_templates', {
            method: 'get',
            params,
        });
    },
    // TOP榜单 - 体测榜单
    getPhysicalListAnalysis(params) {
        return axios('/teens_camp/physical_list_analysis', {
            method: 'get',
            params,
        });
    },
    // 校区统计数据
    getCampusAnalysis(params) {
        return axios('/base/campus/analysis', {
            method: 'get',
            params,
        });
    },
    // 校区统计数据详情
    getCampusAnalysisDetail(params) {
        return axios('/base/campus/analysis/detail', {
            method: 'get',
            params,
        });
    },
    // 获取人员动态表单信息
    getUser_form_data(data) {
        return axios(config.cmsUrl + '/clubhub/user/field_config/scene', {
            method: 'POST',
            data
        })
    },
    // 球员评估详情
    getEvaluation(params) {
        return axios('/evaluation', {
            method: 'get',
            params
        })
    },
    // smartPitch

    getSmartPitchDevices() {
        return axios(config.smartPitchUrl + '/devices', {
            method: 'get',
            params: {
                id: config.smartPitchId
            }
        })
    },
    getSmartPitchStreams() {
        return axios(config.smartPitchUrl + '/streams', {
            method: 'get',
            params: {
                id: config.smartPitchId
            }
        })
    },


}

export default server;
