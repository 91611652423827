<template>
    <span class="indexNum" :style="{ background: spanColor }">
        <i :style="{ background: iColor }"><slot name="number"></slot></i>
    </span>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        spanColor: {
            type: String,
            default: '',
        },
        iColor: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        return {
            ...props,
        }
    },
})
</script>

<style scoped lang="scss">
.indexNum {
    width: 40px;
    height: 40px;
    background: linear-gradient(180deg, #6B4DAD 2.02%, #5933A8 100%);
    box-shadow: inset 0px 0px 8px rgba(14, 9, 19, 0.3);
    display: inline-block;
    border-radius: 40px;
    text-align: center;
    position: absolute;
    z-index: 10;
    i {
        margin-top: 3px;
        background: linear-gradient(180deg, #6F51AE 70.68%, #6646A9 100%);
        width: 34px;
        height: 34px;
        font-style: normal;
        border-radius: 34px;
        font-weight: 800;
        font-size: 28px;
        line-height: 35px;
        font-family: akrobatBold;
        display: inline-block;
        color: #fff;
        box-sizing: border-box;
    }
}
</style>