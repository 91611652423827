<template>
    <div class="pagination">
        <p class="prev pageBtn" :class="currentPage === 1 ? 'disable' : ''" @click="changePage('prev')">上一页</p>
        <p class="num" :style="styleNum">{{ currentPage }}/{{ totalPage }}</p>
        <p class="next pageBtn" :class="currentPage === totalPage ? 'disable' : ''" @click="changePage('next')">下一页</p>
    </div>
</template>

<script>
import {getCurrentInstance} from "vue";

export default {
    name: "index",
    props: {
        currentPage: Number,
        totalPage: Number,
        styleNum: Object
    },
    setup(props) {

        const {proxy} = getCurrentInstance()

        const changePage = (type) => {
            let pages = props.currentPage;
            if (type === 'next') {
                if (pages === props.totalPage) return;
                pages++
                proxy.$emit('changePage', pages)
            }else if (type === 'prev') {
                if (pages === 1) return;
                pages--
                proxy.$emit('changePage', pages)
            }
        }

        return {
            ...props,
            changePage
        }
    }
}
</script>

<style scoped lang="scss">
.pagination {
    display: flex;
    align-items: center;
    text-align: center;

    .pageBtn {
        width: 138px;
        height: 70px;
        background: #714CC0;
        border-radius: 79px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
        color: #FFFFFF;
        font-family: PingFangMedium;
        cursor: pointer;

        &.disable {
            opacity: 0.7;
            cursor: not-allowed;
        }
    }

    .num {
        width: 100px;
        color: #5A35A7;
        font-family: akrobatBold;
        text-align: center;
        font-size: 32px;
        line-height: 40px;
    }
}
</style>