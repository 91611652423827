/* 不同环境，不同俱乐部配置文件，根据  process.env.VUE_APP_NODE ||  process.env.VUE_APP_AUDIENCE  判断 */

// 本地调试地址，方便开发使用
// 打包时可不注释此处，不受影响
let localUrl = 'https://api.test.acme-ai.com/screen/asr'; // 邵聪本地
// let localUrl = 'http://10.0.0.63:83/asr'; // 邵聪本地

let position = 'position'; // 球员位置
// let position = 'courtPosition'; // 球员位置

let parentId = '636b41e79b916954f7cfbd53'; // 测试
// let parentId = '6391586f2569e1227f215082'; //对应集团id 女足
// let parentId = '6371dd4f4612d265a6146b48'; //对应集团id 大连
// let parentId = '636f96eec20de4292bc5fc24'; //对应集团id 灵羲



// 设置智能比赛报告的详情地址
const reportURL = {
    testing: 'https://web.report.test.acme-ai.com', // 测试地址
    production: 'https://web.report.acme-ai.com', // 正式地址
};

// smart pitch
const smartPitchUrl = {
    testing: 'https://fc.acme-ai.com/smart_pitch', // 测试地址
    production: 'https://fc.acme-ai.com/smart_pitch', // 正式地址
};

const publicWedUrl = {
    testing: 'https://web.external.test.acme-ai.com', // 测试地址
    // testing: 'http://10.0.0.176:8080', // 测试地址
    production: 'https://web.external.acme-ai.com', // 正式地址
};

const cmsUrl = {
    testing: 'https://api.test.acme-ai.com', // 测试地址
    production: 'https://api.acme-ai.com', // 正式地址
};

// 素材配置
let CONFIG = {
    RFBC: {
        aiFlag: true,
        aiSpeak: require('./assets/images/ai/speak.png'), // 说话动作图片
        defaultAi: require('./assets/images/ai/wcfa/default.png'), // 默认ai图片
        ai: {
            Speak: {
                width: 1300, // 单张图片的宽度
                height: 1024, // 单张图片的高度
                page: 141, // 共91张图片
                time: 4, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/speak1.png'),
                    require('./assets/images/ai/wcfa/speak2.png'),
                    require('./assets/images/ai/wcfa/speak3.png'),
                    require('./assets/images/ai/wcfa/speak4.png'),
                    require('./assets/images/ai/wcfa/speak5.png'),
                    require('./assets/images/ai/wcfa/speak6.png'),
                    require('./assets/images/ai/wcfa/speak7.png'),
                ]
            }, // 说话动作
            Head: {
                width: 1300, // 单张图片的宽度
                height: 1024, // 单张图片的高度
                page: 91, // 共91张图片
                time: 3, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/head1.png'),
                    require('./assets/images/ai/wcfa/head2.png'),
                    require('./assets/images/ai/wcfa/head3.png'),
                    require('./assets/images/ai/wcfa/head4.png'),
                    require('./assets/images/ai/wcfa/head5.png'),
                ]
            }, // 伸手动作图片
            Heart: {
                width: 1300, // 单张图片的宽度
                height: 1024, // 单张图片的高度
                page: 146, // 共91张图片
                time: 6, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/heart1.png'),
                    require('./assets/images/ai/wcfa/heart2.png'),
                    require('./assets/images/ai/wcfa/heart3.png'),
                    require('./assets/images/ai/wcfa/heart4.png'),
                    require('./assets/images/ai/wcfa/heart5.png'),
                    require('./assets/images/ai/wcfa/heart6.png'),
                    require('./assets/images/ai/wcfa/heart7.png'),
                    require('./assets/images/ai/wcfa/heart8.png'),
                ]
            }, // 比心动作
            Go: {
                width: 1300, // 单张图片的宽度
                height: 1024, // 单张图片的高度
                page: 201, // 共201张图片
                time: 10, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/go1.png'),
                    require('./assets/images/ai/wcfa/go2.png'),
                    require('./assets/images/ai/wcfa/go3.png'),
                    require('./assets/images/ai/wcfa/go4.png'),
                    require('./assets/images/ai/wcfa/go5.png'),
                    require('./assets/images/ai/wcfa/go6.png'),
                    require('./assets/images/ai/wcfa/go7.png'),
                    require('./assets/images/ai/wcfa/go8.png'),
                    require('./assets/images/ai/wcfa/go9.png'),
                    require('./assets/images/ai/wcfa/go10.png')
                ]
            }, // 比心动作
        },
        logo: require('./assets/images/uiConfig/logo.svg'), // logo
        homeBg: require('./assets/images/uiConfig/home-bg.png'), // home页背景
        homeFace: require('./assets/images/uiConfig/home-face.svg'), // home 人脸识别icon
        loginUserBg: require('./assets/images/uiConfig/login-user-bg.png'), // 登录 用户边框图片
        loginBg: require('./assets/images/uiConfig/wcfa-face-bg.png'), // 登录 背景图片
        loginFaceBg: require('./assets/images/uiConfig/wcfa-face-border.png'), // 登录 人脸边框
        playerItemBox: require('./assets/images/uiConfig/player-box.png'), // 球员列表信息框
        playerBag: require('./assets/images/uiConfig/player-bag.png'), // 球员详情背景
        playerDetailsBg: require('./assets/images/uiConfig/player-details-bg.png'), // 人员详情背景
        playerDetailHeader: require('./assets/images/uiConfig/position_title_bag.png'), // 人员详情背景
        color: '#602B89',
    },
    GAFC: {
    },
    WCFA: {
        aiFlag: true,
        aiSpeak: require('./assets/images/ai/speak.png'), // 说话动作图片
        aiHead: require('./assets/images/ai/head.png'), // 伸手动作图片
        defaultAi: require('./assets/images/ai/wcfa/old/default.png'), // 默认ai图片
        ai: {
            Speak: {
                width: 837, // 单张图片的宽度
                height: 1523, // 单张图片的高度
                page: 100, // 共91张图片
                time: 3, // 几秒
                // imgArr: [
                //     require('./assets/images/ai/wcfa/new/speak1.png'),
                //     require('./assets/images/ai/wcfa/new/speak2.png'),
                //     require('./assets/images/ai/wcfa/new/speak3.png'),
                //     require('./assets/images/ai/wcfa/new/speak4.png'),
                //     require('./assets/images/ai/wcfa/new/speak5.png'),
                //     require('./assets/images/ai/wcfa/new/speak6.png'),
                //     require('./assets/images/ai/wcfa/new/speak7.png'),
                // ]
                imgArr: [
                    require('./assets/images/ai/wcfa/old/speak1.png'),
                    require('./assets/images/ai/wcfa/old/speak2.png'),
                    require('./assets/images/ai/wcfa/old/speak3.png'),
                    require('./assets/images/ai/wcfa/old/speak4.png'),
                    require('./assets/images/ai/wcfa/old/speak6.png'),
                ]
            }, // 说话动作
            Head2: {
                width: 1300, // 单张图片的宽度
                height: 1024, // 单张图片的高度
                page: 91, // 共91张图片
                time: 3, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/head1.png'),
                    require('./assets/images/ai/wcfa/head2.png'),
                    require('./assets/images/ai/wcfa/head3.png'),
                    require('./assets/images/ai/wcfa/head4.png'),
                    require('./assets/images/ai/wcfa/head5.png'),
                ],
            }, // 伸手动作图片
            Head: {
                width: 837, // 单张图片的宽度
                height: 1523, // 单张图片的高度
                page: 140, // 共160张图片
                time: 3, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/old/head1.png'),
                    require('./assets/images/ai/wcfa/old/head2.png'),
                    require('./assets/images/ai/wcfa/old/head3.png'),
                    require('./assets/images/ai/wcfa/old/head4.png'),
                    require('./assets/images/ai/wcfa/old/head6.png'),
                    require('./assets/images/ai/wcfa/old/head7.png'),
                    require('./assets/images/ai/wcfa/old/head8.png'),
                ]
                // imgArr: [
                //     require('./assets/images/ai/wcfa/new/head1.png'),
                //     require('./assets/images/ai/wcfa/new/head2.png'),
                //     require('./assets/images/ai/wcfa/new/head3.png'),
                //     require('./assets/images/ai/wcfa/new/head4.png'),
                //     require('./assets/images/ai/wcfa/new/head5.png'),
                // ]
            }, // 伸手动作图片
            Heart: {
                width: 1300, // 单张图片的宽度
                height: 1024, // 单张图片的高度
                page: 146, // 共91张图片
                time: 6, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/heart1.png'),
                    require('./assets/images/ai/wcfa/heart2.png'),
                    require('./assets/images/ai/wcfa/heart3.png'),
                    require('./assets/images/ai/wcfa/heart4.png'),
                    require('./assets/images/ai/wcfa/heart5.png'),
                    require('./assets/images/ai/wcfa/heart6.png'),
                    require('./assets/images/ai/wcfa/heart7.png'),
                    require('./assets/images/ai/wcfa/heart8.png'),
                ]
            }, // 比心动作
            Go: {
                width: 1300, // 单张图片的宽度
                height: 1024, // 单张图片的高度
                page: 201, // 共201张图片
                time: 10, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/go1.png'),
                    require('./assets/images/ai/wcfa/go2.png'),
                    require('./assets/images/ai/wcfa/go3.png'),
                    require('./assets/images/ai/wcfa/go4.png'),
                    require('./assets/images/ai/wcfa/go5.png'),
                    require('./assets/images/ai/wcfa/go6.png'),
                    require('./assets/images/ai/wcfa/go7.png'),
                    require('./assets/images/ai/wcfa/go8.png'),
                    require('./assets/images/ai/wcfa/go9.png'),
                    require('./assets/images/ai/wcfa/go10.png')
                ]
            }, // 比心动作
        },
        logo: require('./assets/images/uiConfig/logo.svg'), // logo
        homeBg: require('./assets/images/uiConfig/home-bg.png'), // home页背景
        homeFace: require('./assets/images/uiConfig/home-face.svg'), // home 人脸识别icon
        loginUserBg: require('./assets/images/uiConfig/login-user-bg.png'), // 登录 用户边框图片
        loginBg: require('./assets/images/uiConfig/login-bg.png'), // 登录 背景图片
        loginFaceBg: require('./assets/images/uiConfig/wcfa-face-bg.png'), // 登录 背景图片
        loginFaceBorder: require('./assets/images/uiConfig/wcfa-face-border.png'), // 登录 人脸边框
        loginFaceBorder2: require('./assets/images/uiConfig/wcfa-face-border2.svg'), // 登录 人脸边框
        loginFaceBorder3: require('./assets/images/uiConfig/wcfa-face-border3.svg'), // 登录 人脸边框
        playerItemBox: require('./assets/images/uiConfig/player-box.png'), // 球员列表信息框
        playerBag: require('./assets/images/uiConfig/player-bag.png'), // 球员详情背景
        playerDetailsBg: require('./assets/images/uiConfig/player-details-bg.png'), // 人员详情背景
        playerDetailHeader: require('./assets/images/uiConfig/position_title_bag.png'), // 人员详情背景
        color: '#602B89',
    },
    LSTAR: {
    },
    TALENT: {
        aiFlag: false,
        aiSpeak: require('./assets/images/ai/speak.png'), // 说话动作图片
        aiHead: require('./assets/images/ai/head.png'), // 伸手动作图片
        defaultAi: require('./assets/images/ai/wcfa/old/default.png'), // 默认ai图片
        ai: {
            Speak: {
                width: 837, // 单张图片的宽度
                height: 1523, // 单张图片的高度
                page: 100, // 共91张图片
                time: 3, // 几秒
                // imgArr: [
                //     require('./assets/images/ai/wcfa/new/speak1.png'),
                //     require('./assets/images/ai/wcfa/new/speak2.png'),
                //     require('./assets/images/ai/wcfa/new/speak3.png'),
                //     require('./assets/images/ai/wcfa/new/speak4.png'),
                //     require('./assets/images/ai/wcfa/new/speak5.png'),
                //     require('./assets/images/ai/wcfa/new/speak6.png'),
                //     require('./assets/images/ai/wcfa/new/speak7.png'),
                // ]
                imgArr: [
                    require('./assets/images/ai/wcfa/old/speak1.png'),
                    require('./assets/images/ai/wcfa/old/speak2.png'),
                    require('./assets/images/ai/wcfa/old/speak3.png'),
                    require('./assets/images/ai/wcfa/old/speak4.png'),
                    require('./assets/images/ai/wcfa/old/speak6.png'),
                ]
            }, // 说话动作
            Head2: {
                width: 1300, // 单张图片的宽度
                height: 1024, // 单张图片的高度
                page: 91, // 共91张图片
                time: 3, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/head1.png'),
                    require('./assets/images/ai/wcfa/head2.png'),
                    require('./assets/images/ai/wcfa/head3.png'),
                    require('./assets/images/ai/wcfa/head4.png'),
                    require('./assets/images/ai/wcfa/head5.png'),
                ],
            }, // 伸手动作图片
            Head: {
                width: 837, // 单张图片的宽度
                height: 1523, // 单张图片的高度
                page: 140, // 共160张图片
                time: 3, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/old/head1.png'),
                    require('./assets/images/ai/wcfa/old/head2.png'),
                    require('./assets/images/ai/wcfa/old/head3.png'),
                    require('./assets/images/ai/wcfa/old/head4.png'),
                    require('./assets/images/ai/wcfa/old/head6.png'),
                    require('./assets/images/ai/wcfa/old/head7.png'),
                    require('./assets/images/ai/wcfa/old/head8.png'),
                ]
                // imgArr: [
                //     require('./assets/images/ai/wcfa/new/head1.png'),
                //     require('./assets/images/ai/wcfa/new/head2.png'),
                //     require('./assets/images/ai/wcfa/new/head3.png'),
                //     require('./assets/images/ai/wcfa/new/head4.png'),
                //     require('./assets/images/ai/wcfa/new/head5.png'),
                // ]
            }, // 伸手动作图片
            Heart: {
                width: 1300, // 单张图片的宽度
                height: 1024, // 单张图片的高度
                page: 146, // 共91张图片
                time: 6, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/heart1.png'),
                    require('./assets/images/ai/wcfa/heart2.png'),
                    require('./assets/images/ai/wcfa/heart3.png'),
                    require('./assets/images/ai/wcfa/heart4.png'),
                    require('./assets/images/ai/wcfa/heart5.png'),
                    require('./assets/images/ai/wcfa/heart6.png'),
                    require('./assets/images/ai/wcfa/heart7.png'),
                    require('./assets/images/ai/wcfa/heart8.png'),
                ]
            }, // 比心动作
            Go: {
                width: 1300, // 单张图片的宽度
                height: 1024, // 单张图片的高度
                page: 201, // 共201张图片
                time: 10, // 几秒
                imgArr: [
                    require('./assets/images/ai/wcfa/go1.png'),
                    require('./assets/images/ai/wcfa/go2.png'),
                    require('./assets/images/ai/wcfa/go3.png'),
                    require('./assets/images/ai/wcfa/go4.png'),
                    require('./assets/images/ai/wcfa/go5.png'),
                    require('./assets/images/ai/wcfa/go6.png'),
                    require('./assets/images/ai/wcfa/go7.png'),
                    require('./assets/images/ai/wcfa/go8.png'),
                    require('./assets/images/ai/wcfa/go9.png'),
                    require('./assets/images/ai/wcfa/go10.png')
                ]
            }, // 比心动作
        },
        logo: require('./assets/images/uiConfig/talent/logo.svg'), // logo
        homeBg: require('./assets/images/uiConfig/talent/home-bg.png'), // home页背景
        homeFace: require('./assets/images/uiConfig/talent/home-face.svg'), // home 人脸识别icon
        loginUserBg: require('./assets/images/uiConfig/login-user-bg.png'), // 登录 用户边框图片
        loginBg: require('./assets/images/uiConfig/login-bg.png'), // 登录 背景图片
        loginFaceBg: require('./assets/images/uiConfig/talent/face-bg.png'), // 登录 背景图片
        loginFaceBorder: require('./assets/images/uiConfig/talent/face-border.png'), // 登录 人脸边框
        loginFaceBorder2: require('./assets/images/uiConfig/talent/face-border2.svg'), // 登录 人脸边框
        loginFaceBorder3: require('./assets/images/uiConfig/wcfa-face-border3.svg'), // 登录 人脸边框
        playerItemBox: require('./assets/images/uiConfig/talent/player-box.png'), // 球员列表信息框
        playerBag: require('./assets/images/uiConfig/talent/player-bag.png'), // 球员详情背景
        playerDetailsBg: require('./assets/images/uiConfig/talent/player-details-bg.png'), // 人员详情背景
        playerDetailHeader: require('./assets/images/uiConfig/talent/position_title_bag.png'), // 人员详情背景
        color: '#4D4EAD',
    }
};

// 暴露JS层面的内容
export default {
    UI_CONFIG: CONFIG[process.env.VUE_APP_AUDIENCE],
    CLUB_NAME: process.env.VUE_APP_AUDIENCE, // 对应俱乐部
    PARENT_ID: process.env.NODE_ENV == 'development' ? parentId : process.env.VUE_APP_PARENT_ID, // 对应集团id
    POSITION: process.env.NODE_ENV == 'development' ? position : process.env.VUE_APP_POSITION, // 对应俱乐部球员位置
    REQUEST_URL: process.env.NODE_ENV == 'development' ? localUrl : process.env.VUE_APP_BASEURL, // 不同环境接口地址
    RTC_URL: 'wss://api.acme-ai.com/asr/rtc',
    TTS_URL: `https://api.acme-ai.com/asr/tts?voice=${process.env.VUE_APP_TTS_VOICE}&text=`,
    currentYearFirstDay: new Date().getFullYear() + '/01/01',
    countriesUrl: 'https://club-pub.oss-cn-beijing.aliyuncs.com/common/images/countries/', // 国籍图片oss地址
    reportURL: reportURL[process.env.VUE_APP_NODE], // 智能比赛报告的详情地址
    smartPitchUrl: smartPitchUrl[process.env.VUE_APP_NODE], // smartPitch
    smartPitchId: process.env.VUE_APP_SMARTPITCH, // smartPitch
    // smartPitchId: '北控弘赫基地', // smartPitch
    publicWedUrl: publicWedUrl[process.env.VUE_APP_NODE], // 公共web页面地址
    cmsUrl: cmsUrl[process.env.VUE_APP_NODE], // 公共web页面地址
};
