<template>
    <header class="people_main">
        <div class="people_box">
            <div class="avatar_box">
                <div class="avatar">
                    <img class="avatar_img" :src="$utils.defaultAvatar(peopleInfo.avatar)" alt />
                </div>
                <span class="number" v-if="peopleInfo.number">{{peopleInfo.number}}</span>
            </div>
            <div class="name_box">
                <div class="name_zn">
                    <p>{{ peopleInfo.name }}</p>
                    <!-- 位置 -->
                    <!-- 球员 -->
                    <div
                        v-if="peopleType === 'player' || peopleType === 'playerEvalDetail'"
                        class="position"
                        :style="{
                            backgroundImage: `url(${peopleInfo.position ? require('@i/common/position_box.png') : ''})`
                        }"
                    >
                        {{ peopleInfo.position || defaultText }}
                    </div>
                    <!-- 教练员 -->
                    <div
                        v-if="peopleType === 'staff' || peopleType === 'staffEvalDetail'"
                        class="position"
                        :style="{
                            backgroundImage: `url(${title ? require('@i/common/position_box.png') : ''})`
                        }"
                    >
                        {{ title || defaultText }}
                    </div>
                    <!--国籍-->
                    <img
                            v-if="peopleInfo.country && (peopleType === 'player' || peopleType === 'playerEvalDetail')"
                            :src="`${$Config.countriesUrl}${peopleInfo.country}.svg`"
                            class="country_img"
                            alt
                    />
                </div>
                <div class="name_en">{{ peopleInfo.nameEn }}</div>
                <div class="player_info" v-if="peopleType === 'player' || peopleType === 'playerEvalDetail'">
                    <span class="label">出生日期：</span><span v-if="peopleInfo.birthday">{{peopleInfo.birthday}}</span>
                </div>
                <!-- 先注释，不要 -->
                <!-- <div v-if="isScoreShow" class="score">
                    <img
                        v-for="i in 5"
                        :src="
                            i <= Math.floor(peopleInfo ? peopleInfo.star : 0)
                            ? require('@i/common/xingFull.svg')
                            : require('@i/common/xingNo.svg')
                        "
                        alt
                    />
                </div> -->
            </div>
        </div>
        <!-- 球员 -->
<!--        <div v-if="peopleType === 'player'" class="people_info">-->
<!--            <div class="people_row">-->
<!--                <dl>-->
<!--                    <dt>过敏史</dt>-->
<!--                    <dd>{{ peopleInfo.allergy || defaultText }}</dd>-->
<!--                </dl>-->
<!--            </div>-->
<!--        </div>-->
        <!-- 教练员 -->
<!--        <div v-if="peopleType === 'staff'" class="people_info">-->
<!--            <div class="people_row">-->
<!--                <dl>-->
<!--                    <dd class="akrobatRegular">{{ peopleInfo.height || defaultText }}</dd>-->
<!--                    <dt>身高</dt>-->
<!--                </dl>-->
<!--                <dl>-->
<!--                    <dd>-->
<!--                        <span class="akrobatRegular">{{ peopleInfo.weight || defaultText }}</span>-->
<!--                        <span v-if="peopleInfo.weight" class="unit">KG</span>-->
<!--                    </dd>-->
<!--                    <dt>体重</dt>-->
<!--                </dl>-->
<!--                <dl>-->
<!--                    <dd>{{ peopleInfo.bloodType || defaultText }}</dd>-->
<!--                    <dt>血型</dt>-->
<!--                </dl>-->
<!--            </div>-->
<!--            <div class="people_row">-->
<!--                <dl>-->
<!--                    <dd class="akrobatRegular">{{ peopleInfo.phone || defaultText }}</dd>-->
<!--                    <dt>手机号</dt>-->
<!--                </dl>-->
<!--            </div>-->
<!--        </div>-->
    </header>
</template>

<script>
import { defineComponent, computed, toRefs, reactive, watch } from 'vue';
export default defineComponent({
    props: {
        // 人员信息
        list: {
            type: Object,
            default: () => { }
        },
        title: {
            type: String,
            default: ''
        },
        /**
         * 人员类型
         * 球员：player
         * 教练员：staff
         * 伤病: injury
         */
        peopleType: {
            type: String,
            default: 'player'
        },
    },
    setup(props) {
        // 是否显示评分
        const isScoreShow = computed(() => {
            const arr = ['injury', 'playerEvalDetail', 'staffEvalDetail'];
            return arr.indexOf(props.peopleType) === -1;
        });
        // 当前位置
        // const currentPos = computed(() => {
        //     let text = props.getValueByKeyStatic(state.peopleInfo.position, props.playerPositionStatic)
        //     return text;
        // });

        watch(
            () => props.list,
            (newVal) => {
                if (newVal) {
                    state.peopleInfo = newVal;
                }
            }
        );
        const state = reactive({
            defaultText: '-', // 默认占位符,
            peopleInfo: {}
        });

        return {
            isScoreShow,
            // currentPos,
            ...props,
            ...toRefs(state),
        }
    },
})
</script>
<style lang='scss' scoped>
.people_main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 40px 0 0 94px;
    .people_box {
        display: flex;
        align-items: center;
        height: 100%;
        .avatar_box {
            width: 250px;
            height: 250px;
            margin-right: 60px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: url("~@/assets/images/common/player-head-bg.png") no-repeat center;
            background-size: 100% 100%;
            box-shadow: 0 40px 60px rgba(4, 0, 90, 0.2);
            .avatar {
                width: 239px;
                height: 239px;
                overflow: hidden;
                flex-shrink: 0;
                border-radius: 50%;
                .avatar_img {
                    width: 100%;
                }
            }
            .number{
                position: absolute;
                bottom: 0;
                right: 0;
                width: 70px;
                height: 66px;
                background: url("../../assets/images/common/clothing.png") no-repeat center;
                background-size: 100% 100%;
                font-family: akrobatExtraBold;
                font-size: 38px;
                line-height: 67px;
                text-align: center;
                color: #FFFFFE;
                text-shadow: 0 1.95096px 0 rgba(0, 0, 0, 0.25);
            }
        }
        .name_box {
            color: #3A3A3A;
            .name_zn {
                display: flex;
                align-items: center;
                line-height: 84px;
                p {
                    font-weight: 600;
                    font-size: 60px;
                    max-width: 400px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #333333;
                }
                .position {
                    margin-left: 32px;
                    padding: 0 10px;
                    height: 45px;
                    line-height: 45px;
                    background-image: url("~@i/common/position_box.png");
                    background-size: 100% 100%;
                    font-weight: 400;
                    font-size: 32px;
                    color: #FFFFFF;
                    margin-right: 32px;
                }
                .country_img{
                    width: 68px;
                    height: 45px;
                }
            }
            .name_en {
                font-size: 28px;
                line-height: 39px;
                color: #B7B7BF;
                padding-bottom: 16px;
            }
            .player_info{
                font-size: 28px;
                line-height: 45px;
                .label {
                    font-size: 24px;
                }
                >span{
                    font-family: akrobatRegular;
                }
            }
            .score {
                margin-top: 24px;
                img {
                    width: 52px;
                    height: 52px;
                    margin-right: 12px;
                }
            }
        }
    }
    .people_info {
        flex: 1;
        box-sizing: border-box;
        padding-left: 150px;
        .people_row {
            display: flex;
            align-items: flex-end;
            dl {
                margin-bottom: 32px;
                color: #3a3a3a;
                width: 190px;
                dd {
                    font-size: 32px;
                    line-height: 45px;
                }
                dt {
                    font-size: 28px;
                    line-height: 39px;
                    color: #81818A;
                }
            }
        }
    }
}
</style>
